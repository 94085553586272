$base-app-color: #0083ff;
$BlueTextColor: #54b0dc;
$font-size: 18px;
$fontStyles: 'Times New Roman', Times, serif;
$BlackFontColor: black;
$whiteFontColor: white;
$greyFontColor: #979797;
$successGreenColor: #34eb83;
$pushNotificationBackground: white;
$designTextColor: #000000;
$lightWhite: rgba(255, 255, 255, 0.1);
$fontSize: 18px;
$darkOpacity: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%);
$whiteOpacity: linear-gradient(to left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
$whiteColor: rgba(255, 255, 255, 0.7);

@font-face {
  font-family: 'AppFont';
  src:
    url('../fonts/roboto/Roboto-Light.ttf') format('ttf'),
    url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyBlack';
  src:
    url('../fonts/gilroy/Gilroy-Black.ttf') format('ttf'),
    url('../fonts/gilroy/Gilroy-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'GilroyLight';
  src:
    url('../fonts/gilroy/Gilroy-Light.ttf') format('ttf'),
    url('../fonts/gilroy/Gilroy-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyBold';
  src:
    url('../fonts/gilroy/Gilroy-Bold.ttf') format('ttf'),
    url('../fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'GilroyRegular';
  src:
    url('../fonts/gilroy/Gilroy-Regular.ttf') format('ttf'),
    url('../fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/roboto/Roboto-Regular.ttf') format('ttf'),
    url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'RobotoLight';
  src:
    url('../fonts/roboto/Roboto-Light.ttf') format('ttf'),
    url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'RobotoBlack';
  src:
    url('../fonts/roboto/Roboto-Black.ttf') format('ttf'),
    url('../fonts/roboto/Roboto-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'RobotoBold';
  src:
    url('../fonts/roboto/Roboto-Bold.ttf') format('ttf'),
    url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

.vis canvas {
  touch-action: auto !important;
  display: block;
}

.vis {
  display: block;
}

.creatFix canvas {
  touch-action: auto !important;
  display: block;
}

.creatFix {
  display: block;
}

.mainpictures {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  align-content: space-around;
}

.mainvideo {
  margin-top: 20px;
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videoframe {
  width: 600px;
  height: 400px;
}

.uap {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 420px) and (max-height: 1440px) {
  .mainpictures {
    display: grid;
    grid-template-columns: 12.5% 27% 23% 27%;
  }

  .mainvideo {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 13% 80%;
    align-content: center;
  }

  .videoframe {
    width: 300px;
    height: 200px;
  }
}

.blockClass {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
}

.approvalBysystem {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.approvalText {
  color: $base-app-color;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}

.BloggerProgress {
  width: 100% !important;
}
.ContactsProgress {
  width: 100% !important;
}

.goOnlineStyleMargin {
  margin-top: 9px;
  padding-left: 10px;
  padding-right: 10px;
}

.CurrentTask {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 80px;
}

.CurrentTaskTwo_2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.CurrentTaskTwo_2Text {
  font-family: RobotoLight !important;
  font-size: 25px;
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
}

.gm-fullscreen-control {
  display: none !important;
}

.gm-svpc {
  display: none !important;
}
.gmnoprint {
  display: none !important;
}
::-webkit-scrollbar {
  display: none !important;
}
.withoutScroll {
  ::-webkit-scrollbar {
    display: none !important;
  }
}
body {
  /* background-color:$base-app-color; */
  background-color: white !important;
  background: black !important;
}

.mainPush_root {
  position: absolute;
  left: 0px;
  bottom: 5px;
  overflow: hidden;
  width: 100%;
  height: 320px;
  background-color: $pushNotificationBackground;
}

.model1PushRoot {
  position: absolute !important;
  left: 0px;
  bottom: 0px;
}

.PushVideoStylesCoverMargin {
  margin-top: 15px;
  margin-bottom: 15px;
}
.PushVideoStyles {
  width: 100%;
  z-index: 50;
}

.mainPush_rootModel1 {
  overflow: hidden;
  width: 100%;
  height: 405px;
  background-color: $pushNotificationBackground;
}

.mainPushModel1 {
  overflow: hidden;
  width: 100%;
  height: 352px;
}

.mainPushColumsTwoModel1 {
  width: 100%;
  height: 44%;
}

.mainPushColumsOneM1 {
  width: 100%;
  height: 28%;
}
.mainPushColumsCenterBlockM1 {
  width: 100%;
  height: 28%;
}

.mainPushColumsCenterBlockM1Description {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  color: black;
  font-family: AppFont;
  max-height: 85px;
}

.declineButtonBlockM1 {
  overflow: hidden;
  width: 100%;
  height: 52px;
}

.declineButtonStyleM1 {
  margin-top: 5px;
  margin-left: 5px;
  width: 100px;
  height: 42px;
  border-radius: 25px;
  background: linear-gradient(to right, $base-app-color, $base-app-color);
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  padding-top: 8px;
}

.declineButtonBlock {
  overflow: hidden;
  width: 100%;
  height: 60px;
}

.declineButtonStyle {
  margin-top: 10px;
  margin-left: 5px;
  width: 100px;
  height: 42px;
  border-radius: 25px;
  background: linear-gradient(to right, $base-app-color, $base-app-color);
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  padding-top: 8px;
}

.mainPush {
  overflow: hidden;
  width: 100%;
  height: 260px;
}

.mainPushColumsOne {
  width: 100%;
  height: 38%;
}

.mainPushColumsOneLeft {
  width: 77%;
  height: 100%;
  float: left;
  padding-top: 7px;
  padding-left: 10px;
}

.mainPushColumsOneLeft_1 {
  color: $BlackFontColor !important;
  height: 28%;
  font-size: 22px;
}
.mainPushColumsOneLeft_2 {
  color: $BlackFontColor !important;
  height: 36%;
  font-size: 22px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainPushColumsOneLeft_3 {
  color: $greyFontColor !important;
  height: 27%;
  font-size: 18px;
}

.mainPushColumsOneRight {
  width: 23%;
  height: 100%;
  float: left;
  overflow: hidden;
}

.CircularProgressParent {
  margin-top: 10px;
  margin-left: 7px;
}

.CircularProgressCover {
  position: relative;
  width: 60px !important;
  height: 60px !important;
}

.CircularProgress {
  width: 60px !important;
  height: 60px !important;
  color: $base-app-color !important;
}

.CircleTimerCover {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.CircleTimer {
  width: 100%;
  height: 100%;
  font-size: 20px;
  line-height: 60px;
  font-family: AppFont;
  color: $base-app-color !important;
}

.mainPushColumsTwo {
  width: 100%;
  height: 62%;
}

.mainPushColumsTwo_1 {
  width: 100%;
  height: 62%;

  overflow: hidden;
}

.gorizontalGreyLine {
  width: 90%;
  margin-left: 17px;
  height: 2px;
  background-color: $greyFontColor !important;
}

.mainPushColumsTwo_1_Price {
  width: 100%;
  height: 50px;
  font-weight: bold;
  color: $BlackFontColor !important;
  font-size: 36px;
  text-align: center;
}

.mainPushColumsTwo_2_Second {
  width: 100%;
  height: 24px;
  font-size: 16px;
  text-align: center;
  color: $greyFontColor !important;
}

.mainPushColumsTwo_3_Third {
  width: 100%;
  height: 22px;
  font-size: 16px;
  text-align: center;
  color: $greyFontColor !important;
}

.mainPushColumsTwo_2 {
  width: 100%;
  height: 38%;
  overflow: hidden;
}

@media only screen and (max-width: 2560px) and (max-height: 1440px) {
  .MainLogoCenter {
    width: 100%;
    height: 250px;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -120px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -65px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }

  .LoginButtonFrame {
    width: 95px;
    height: 35px;
    border-radius: 15px;
    border: 2px solid $BlueTextColor;
    margin-left: 43%;
  }
}

@media only screen and (max-width: 1950px) and (max-height: 995px) {
  .creatorAnim {
    width: 100%;
    height: 550px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 1350px) and (max-height: 670px) {
  .creatorAnim {
    width: 100%;
    height: 380px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 1040px) and (max-height: 1370px) {
  .creatorAnim {
    width: 100%;
    height: 730px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 1040px) and (max-height: 1050px) {
  .creatorAnim {
    width: 100%;
    height: 550px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 780px) and (max-height: 1050px) {
  .creatorAnim {
    width: 100%;
    height: 550px !important;
    overflow: hidden;
  }

  .MainLogoCenter {
    width: 100%;
    height: 350px;
  }

  .secondAnimation {
    margin-top: -190px !important;
  }

  .secondThird {
    margin-top: -280px !important;
  }
  .secondFourth {
    margin-top: -230px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -150px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -65px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }

  .LoginButtonFrame {
    width: 95px;
    height: 35px;
    border-radius: 15px;
    border: 2px solid $BlueTextColor;
    margin-left: 39%;
  }
}

@media only screen and (max-width: 420px) and (max-height: 1440px) {
  .MainLogoCenter {
    width: 100%;
    height: 250px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
}

@media only screen and (max-width: 430px) and (max-height: 930px) {
  .MainLogoCenter {
    width: 100%;
    height: 250px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -170px !important;
  }

  .secondThird {
    margin-top: -255px !important;
  }
  .secondFourth {
    margin-top: -210px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -125px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -40px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}
@media only screen and (max-width: 420px) and (max-height: 900px) {
  .MainLogoCenter {
    width: 100%;
    height: 250px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -170px !important;
  }

  .secondThird {
    margin-top: -255px !important;
  }
  .secondFourth {
    margin-top: -210px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -125px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -40px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

@media only screen and (max-width: 420px) and (max-height: 892px) {
  .MainLogoCenter {
    width: 100%;
    height: 280px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -140px !important;
  }

  .secondThird {
    margin-top: -210px !important;
  }
  .secondFourth {
    margin-top: -180px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -105px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -35px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

@media only screen and (max-width: 420px) and (max-height: 896px) {
  .creatorAnim {
    width: 100%;
    height: 470px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 420px) and (max-height: 840px) {
  .MainLogoCenter {
    width: 100%;
    height: 280px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -140px !important;
  }

  .secondThird {
    margin-top: -210px !important;
  }
  .secondFourth {
    margin-top: -180px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -105px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -35px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

@media only screen and (max-width: 420px) and (max-height: 736px) {
  .creatorAnim {
    width: 100%;
    height: 390px !important;
    overflow: hidden;
  }
}

@media only screen and (max-width: 380px) and (max-height: 820px) {
  .creatorAnim {
    width: 100%;
    height: 430px !important;
    overflow: hidden;
  }

  .MainLogoCenter {
    width: 100%;
    height: 250px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -140px !important;
  }

  .secondThird {
    margin-top: -210px !important;
  }
  .secondFourth {
    margin-top: -170px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -105px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -35px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

@media only screen and (max-width: 380px) and (max-height: 712px) {
  .creatorAnim {
    width: 100%;
    height: 380px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 380px) and (max-height: 670px) {
  .creatorAnim {
    width: 100%;
    height: 350px !important;
    overflow: hidden;
  }

  .MainLogoCenter {
    width: 100%;
    height: 250px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -130px !important;
  }

  .secondThird {
    margin-top: -190px !important;
  }
  .secondFourth {
    margin-top: -170px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -100px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -35px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

@media only screen and (max-width: 365px) and (max-height: 745px) {
  .creatorAnim {
    width: 100%;
    height: 380px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 380px) and (max-height: 650px) {
  .MainLogoCenter {
    width: 100%;
    height: 200px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -130px !important;
  }

  .secondThird {
    margin-top: -185px !important;
  }
  .secondFourth {
    margin-top: -160px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -100px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -35px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

@media only screen and (max-width: 360px) and (max-height: 640px) {
  .creatorAnim {
    width: 100%;
    height: 340px !important;
    overflow: hidden;
  }

  .MainLogoCenter {
    width: 100%;
    height: 200px;
  }

  .buttonStylePushText {
    font-family: AppFont !important;
    font-size: 22px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .secondAnimation {
    margin-top: -130px !important;
  }

  .secondThird {
    margin-top: -185px !important;
  }
  .secondFourth {
    margin-top: -160px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -100px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -35px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

@media only screen and (max-width: 330px) and (max-height: 600px) {
  .creatorAnim {
    width: 100%;
    height: 300px !important;
    overflow: hidden;
  }

  .secondAnimation {
    margin-top: -110px !important;
  }

  .secondThird {
    margin-top: -165px !important;
  }
  .secondFourth {
    margin-top: -140px !important;
    z-index: 80;
  }

  .JoinClass {
    width: 100%;
    text-align: center;
    margin-top: -80px;
    z-index: 90 !important;
  }

  .Echohub_policy {
    margin-top: -15px;
    width: 100%;
    height: 90px;

    z-index: 90 !important;
  }
}

.bloggerAWrap {
  margin-left: 5%;
  margin-right: 5%;
}

.switchBox {
  width: 100%;
  height: 50px;

  margin-top: 12px;
  overflow: hidden;
}

.switchBoxIfl {
  width: 100%;
  height: 60px;

  margin-top: 12px;
  overflow: hidden;
}

.switchBoxTwo {
  width: 90%;
  height: 80px;

  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 5px;

  overflow: hidden !important;
}

.lswitchBoxTwo {
  float: left;
  text-align: center;
  padding-top: 7px;
  width: 75%;
  height: 100%;

  padding: 8px 8px 8px 32px;
  font-size: 16px;
  font-family: RobotoLight;
  font-style: normal;
  color: #000000;
  font-weight: bold;
  overflow: hidden !important;
}
.rswitchBoxTwo {
  float: left;
  width: 25%;
  height: 100%;
  overflow: hidden !important;
}

.lswitchBox {
  float: left;
  text-align: left;
  padding-top: 7px;
  width: 75%;
  height: 100%;

  font-family: $fontStyles;
  font-size: $font-size;
  color: $base-app-color;
}
.rswitchBox {
  float: left;
  width: 25%;
  height: 100%;
}

.switchCheckbox {
  background-color: RGBA(0, 131, 255, 0.11);
  border-radius: 10px;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: $base-app-color !important;
}

.appColor {
  color: $base-app-color;
}

.makeStyles-drawerHeader-11 {
  background-color: $base-app-color;
}

.commonBackgroundColor {
  background: white !important;
}

.leftDrawer {
  color: $base-app-color !important;
}
.makeStyles-drawerHeader-11 {
  color: $base-app-color !important;
}

.MuiButton-label {
  color: $base-app-color !important;
  font-family: RobotoLight !important;
  font-style: normal;
}

.MuiTypography-colorInherit {
  color: $base-app-color !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.logo-position {
  width: 100%;
  height: 100px;
}

.MainLCenterWrap {
  width: 100%;
  overflow: hidden;
}

.MainLCenter {
  width: 100%;
  margin-left: 15%;
}
.MainLogo {
  /* base styles */
  width: 35ch;
  height: 22ch;
}

.MainLCenterWrapAbout {
  width: 80%;
  overflow: hidden;
  margin-left: 10%;
}

.dandelion > canvas {
  width: 100% !important;
  height: 100% !important;
  z-index: 1 !important;
}

.dandelion {
  z-index: 1 !important;
}

.b_letter {
  width: 40%;
  height: 100%;
  background-image: linear-gradient($base-app-color, $base-app-color);
  /*background-color:#0bbbe1;*/
  /*background-size: 100%;*/
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-repeat: repeat;
  /*color:linear-gradient(to right, #8936f4, $base-app-color);*/
  font-size: 100px;
  float: left;
  font-weight: bold;
  animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
}

.mainCentralDiv {
  /* base styles */
  color: $base-app-color;
  width: 100%;
  height: 20px;

  text-align: center;
  font-size: 20px;
  font-family: AppFont;
}

.payPalDiv {
  /* base styles */
  color: $base-app-color;
  width: 100%;

  text-align: center;
  font-size: 20px;
  font-family: AppFont;
}

.forgot-password {
  color: white;
  width: 100%;
  height: 20px;

  text-align: center;
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif;
}
.colorWhite {
  color: white;
}

.main-input-padding {
  margin-top: 15%;
}

.buttonDiv {
  width: 100%;
  height: 45px;
  margin-top: 5%;
}

.secondMargin {
  margin-top: 10px !important;
  width: 100%;
}
.datepickerColor {
  margin-top: 10px !important;
  width: 100%;
  color: $base-app-color;
}

.MuiIconButton-root {
  color: white !important;
}

.location-search-input {
  margin-top: 10px !important;
  width: 100%;
  color: $base-app-color;
}

.textArea {
  margin-top: 10px !important;
  width: 100%;
}

.MuiFormLabel-root {
  /* base styles */
  /* color:#8936f4 !important; */
  color: $base-app-color !important;
}

.buttonStyle {
  /* base styles */
  width: 50%;
  height: 100%;
  /* background:linear-gradient(to right, #8936f4, $base-app-color); */
  background: linear-gradient(to right, $base-app-color, $base-app-color);
  border-radius: 15px;
  color: white;
  border: 0px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px;
}

.center-button {
  margin-left: 25%;
}

.buttonMargin {
  margin-top: 25px;
}

.buttonText {
  color: white;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  width: 100%;
  height: 5px;
  padding-top: 5%;
  font-size: 16px;
}

.errorBox {
  margin-top: 5px;
  padding: 5px;
  border: 2px solid red;
  border-radius: 5px;
}

ion-item {
  --highlight-color-focused: linear-gradient(to right, $base-app-color, $base-app-color) !important;
  --highlight-color-invalid: red !important;
  --highlight-color-valid: green !important;
  color: white;
}

.linearButtonColors {
  background: linear-gradient(to right, red 50%, blue 50%);
  background-size: 200% 100%;
  background-position: left bottom;
}

.MuiFormHelperText-root {
  color: red !important;
}

.MuiInputBase-input {
  /* color:white !important; */
  /* base styles */
  font: initial !important;
  letter-spacing: initial !important;
  font-family: 'AppFont' !important;
  border-color: $base-app-color;
  font-weight: normal;
}

.MuiInputBase-root {
  color: $base-app-color !important;
}

.center-text {
  text-align: center;
}

.showText {
  color: $base-app-color;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-align: left;
  font-size: 1rem;
  padding-left: 2px;
  padding-top: 4px;
}

.back-icon-div {
  width: 70px;
  height: 70px;
  float: left;
}

.back-button-with-center {
  width: 100%;
  height: 70px;
}

.back-icon {
  margin-top: 10px;
  margin-left: 10px;
}

.StarDiv {
  width: 100%;
  height: 35px;
  margin-top: 15%;
}

.firstStar {
  width: 15%;
  float: left;
  font-size: 35px !important;
  margin-left: 20%;

  text-align: center;
}

.creatorStarDiv {
  width: 100%;
  height: 35px;
  margin-top: 5px;
  padding-left: 10px;
}

.creatorsStar {
  width: 15%;
  float: left;
  font-size: 22px !important;
  margin-left: 2px;

  text-align: center;
}

.secondStar {
  width: 15%;
  font-size: 35px !important;
  float: left;
  margin-left: 3%;

  text-align: center;
}

.commentBox {
  width: 100%;
  height: 100px;
  margin-top: 20px;
}

.usualColorStatus {
  color: $base-app-color;
}

.yellowColorStatus {
  color: yellow;
}

.blueColorStatus {
  color: #2d69f6;
}

.alertPosition {
  width: 70%;
  position: fixed !important;
  left: 15%;
  top: 70%;
  z-index: 2000;
}
.alertBoxPosition {
  margin-top: 5px;
  width: 100%;
}

.profileBlock {
  width: 60%;
  height: 40px;
  border: 1px solid white;
  margin-left: 22%;
}
.profileInformation {
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  margin-top: 8px;
  color: $base-app-color;
}

.deleteUrlClass {
  text-decoration: none;
}

.backstyle {
  margin-top: 11px;
  margin-left: 20px;
  font-size: 22px;
  color: white !important;
}

.backabsolute {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 55px;
  height: 55px;
  z-index: 2000 !important;
  background-color: rgba(0, 132, 255, 0.41);
  border-radius: 5px;
}

.cs-main-container {
  position: fixed !important;
  width: 100%;
  height: 93% !important;
  bottom: 50px !important;
  left: 0px !important;
}
.cs-message-input {
  position: fixed !important;
  width: 100%;
  color: $base-app-color !important;
  bottom: 0px !important;
  left: 0px !important;
}

.cs-message-input__content-editor {
  color: $base-app-color !important;
}

.MuiAutocomplete-root {
  border-color: $base-app-color !important;
}

.MuiFormControl-root {
  border-color: $base-app-color !important;
}

.myToolbar {
  width: 100%;
  height: 35px !important;
  overflow: hidden;
}

.leftM {
  width: 10% !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 4px !important;
  float: left;
}
.centerM {
  width: 80% !important;
  padding: 0px !important;
  padding-left: 10px !important;
  margin: 0px !important;
  float: left;
  font-style: $fontStyles !important;
}
.rightM {
  width: 10% !important;
  text-align: right !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 4px !important;
  float: left;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
}

.askBox {
  margin-top: 7%;
  margin-left: 4%;
  width: 90%;
  padding-bottom: 45px;
  border: 2px solid $base-app-color;
}

.askBoxText {
  margin-top: 35px;
  padding-left: 2px;
  padding-right: 2px;
  font-family: 'AppFont';
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: $base-app-color;
}

.questionBox {
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
}

.textFieldAppStyle {
  width: 100%;
}

.manageButtons {
  margin-top: 24%;
  width: 100%;
  text-align: center;
}
.stepperBox {
  margin-bottom: 0%;
  width: 100%;
}
.fullSelect {
  width: 100%;
}
.MuiInput-underline {
  border-color: $base-app-color !important;
}
.MuiInputBase-input {
  border-color: $base-app-color !important;
}

.Mui-error :before {
  background-color: $base-app-color;
}
.Mui-error :after {
  background-color: red;
}
.MuiButton-containedPrimary {
  background-color: $base-app-color !important;
}
.MuiButton-containedPrimary > .MuiButton-label {
  color: white !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: $base-app-color !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: $base-app-color !important;
}
.MuiStep-horizontal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.TextFieldStyle {
  font-family: 'AppFont';
}

.textFieldAppStyleTwo {
  margin-top: 5px !important;
  width: 100%;
}
.App {
  text-align: center;
  width: 100%;
}

.successUpload {
  width: 100%;
  margin-top: 7px;
  font-size: 30px !important;
  text-align: center;
  color: $successGreenColor;
}
.successUploadSmallIcon {
  width: 100%;
  font-size: 24px !important;
  text-align: center;
  color: $successGreenColor;
}

.VideoImageStyleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AppFont;
  padding: 15px;
}

.VideoImageStyle {
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AppFont;
  width: 80px !important;
  height: 80px !important;

  float: left;
}

.videoSecondBlock {
  margin-top: 32px;
  width: 200px;
  height: 14px;
  margin-left: 5px;
  background-color: lightblue;
}

.VideoPreviewStyle {
  margin: 20px 5px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AppFont;
  width: 250px !important;
  height: 200px !important;
  float: left;
}

.UploadImageStyleSecond {
  width: 80px !important;
  height: 80px !important;
  float: left;
}
.UploadImageStyle {
  margin-left: 20px;
  width: 80px !important;
  height: 80px !important;
  float: left;
}

.imageSecondBlock {
  margin-top: 32px;
  width: 100px;
  height: 14px;
  margin-left: 5px;
  float: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.uploadStyles {
  width: 200px;
  height: 200px;
  margin-left: 100px;
  margin-top: 100px;
}

.fileClass {
  width: 40%;
  height: 30px;
  float: left;
}
.buttonClassImage {
  width: 50%;
  height: 30px;
  float: left;
  margin-left: 5px;
}

.accordionStyle {
  display: initial !important;
}

.accordeonTitle {
  width: 100%;
}
.accordeonTitleOne {
  margin-left: 10%;
  width: 60%;
  color: $base-app-color;
  float: left;
}
.accordeonTitleTwoNot {
  width: 30%;
  float: left;
  color: orange;
}
.accordeonTitleTwoBlocked {
  width: 30%;
  float: left;
  color: black;
}
.accordeonTitleTwoApproved {
  width: 30%;
  float: left;
  color: $base-app-color;
}
.adminSearchField {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
}

.appContainer {
  width: 90%;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
}

.appPage {
  width: 100%;
}

.appContainerOne {
  width: 90%;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
}
.approveButtonGroup {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
}

.approveButtonGroupOne {
  width: 30%;
  margin-top: 5px;
  color: $base-app-color;
  float: left;
}

.approveButtonGroupTwo {
  width: 30%;
  float: left;
}
.approveButtonGroupThree {
  width: 30%;
  float: left;
}

.ButtonTextWhite span {
  color: white !important;
}

.descriptionMargin {
  margin-top: 10px;
  width: 70;
  overflow: hidden;
  margin-left: 25%;
}

.appContainerDescribtion {
  width: 100%;
  overflow: hidden;
  margin: 5px;
}
.appContainerDescribtionOne {
  width: 30%;
  float: left;
  color: $base-app-color;
}
.appContainerDescribtionTwo {
  width: 30%;
  float: left;
  color: $base-app-color;
}

.appProfile {
  color: black;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-size: 20px;
}

.PushButton {
  background-color: red;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 15px;
  text-align: center;
  padding-top: 14px;
  margin-top: 200px;
  margin-left: 30%;
}

.iosTop {
  margin-top: 30px;
}

.BusinessVideo {
  width: 100%;
  z-index: 50;
}
.BlogMinus {
  margin-top: -5px;
  display: block;
}

.fullChat {
  width: 100%;
}
.chatstyles {
  margin-bottom: 5px;
}

.echohub_child {
  float: left;
  width: 30.5%;
  height: 70px;
  margin-left: 7px;
  margin-top: 9px;
  color: white;
  font-family: AppFont;
  font-size: 11px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.echohub_child_text_url {
  color: white;
  font-family: AppFont;
}

.echohub_child_text {
  padding-bottom: 5px;
  color: white;
  font-family: AppFont;
}

.textPadding {
  padding-bottom: 5px;
  color: white;
  font-family: AppFont;
}

.aboutUs {
  margin-top: 10px;
  overflow: hidden;
  width: 100%;
  padding-left: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.aboutUs_child {
  overflow: hidden;
  width: 93%;
  height: 40px;
  background-color: white;
  border: 2px solid #2c3d77;
}

.aboutUs_child_text {
  overflow: hidden;
  margin-top: 8px;
  font-family: AppFont;
  color: #2c3d77;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.aboutUs_childTop {
  margin-top: 6px;
}

.MuiStepper-root {
  padding: 2px !important;
}

.firstLevelStepper {
  width: 96.5%;
  height: 24%;
  /* background-color: blue; */
  margin-top: 7px;
  margin-left: 5px;
  border-radius: 7px;
}

.secondLevelStepper {
  width: 96.5%;
  height: 64%;
  margin-top: 5px;
  margin-left: 5px;
}

.MainBlockStepper {
  width: 100%;
  height: 140px;
  margin-left: 0px;
  margin-right: 5px;
  border-radius: 7px;

  margin-top: 5px;
  overflow: hidden;
  border: 2px solid $base-app-color;
  font-family: AppFont !important;
  padding-bottom: 4px;
}

.MainBlockStepperWithoutFrame {
  width: 100%;
  height: 100px;
  margin-left: 0px;
  margin-right: 5px;
  border-radius: 7px;

  margin-top: 5px;
  overflow: hidden;
  font-family: AppFont !important;
  padding-bottom: 4px;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  color: $base-app-color !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: $base-app-color !important;
}

.firstLevelTextStepper {
  padding: 4px 0px 0px 7px;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 80%;
  width: 100%;
  color: $base-app-color;
  font-size: $fontSize;
  font-weight: bold;
}

.MuiTypography-body2 {
  font-family: AppFont !important;
}

.menuAbsolute {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 55px;
  height: 55px;
  z-index: 2000 !important;
  background-color: rgba(0, 132, 255, 0.41);

  border-radius: 5px;
}

.menuButtonstyle {
  margin-top: 12px;
  margin-left: 12px;
  font-size: 30px !important;
  color: white !important;
}

.MainMunuBlock {
  margin-top: 158px;
  width: 100%;
  height: 39px;

  padding-top: 2px;
  padding-bottom: 2px;
}
.MenuList {
  width: 90%;
  height: 37px;
  margin-left: 5%;
}

.LeftMenuList {
  float: left;
  width: 10% !important;
  font-size: 30px !important;
  margin-top: 3px;
  color: $base-app-color;
  margin-left: 4px;
}
.RightMenuList {
  float: left;
  width: 70%;
  margin-top: 8px;
  margin-left: 7px;
  font-family: AppFont;
  font-weight: bolder;
  color: $base-app-color;
}

.TitleDivider {
  background-color: $base-app-color !important;
  width: 60%;
  height: 2px;
  margin-left: 20%;
  float: left;
}
.ListDivider {
  background-color: $base-app-color !important;
  width: 80%;
  height: 2px;
  margin-left: 10%;
  float: left;
}

.BlockDivider {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $base-app-color !important;
  width: 90%;
  height: 2px;
  margin-left: 5%;
  float: left;
}

.BlockDividerTinLine {
  margin-bottom: 2px;
  background-color: white;
  width: 91%;
  height: 1px;
  margin-left: 4%;
  float: left;
}

.dialogCenterText {
  text-align: center;
}
.imgCenter {
  width: 100%;
  text-align: center;
}

.echohubMobileRoot {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.echohubMobile {
  font-family: AppFont;
  font-size: 20px;
  text-align: center;
}
.DivAppBackground {
  background-color: $base-app-color;
}
.TopM {
  margin-top: 30px;
}
.Echohub_policyAbout {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 90px;

  z-index: 90 !important;
}

.aboutUsComponent {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  color: $base-app-color;
  padding: 10px;
  font-family: AppFont;
  font-size: 18px;
}

.LoginButtonName {
  color: $BlueTextColor;
  font-family: AppFont;
  font-size: 18px;
  margin-top: 3px;
}

.buttonCenterSocial {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AppFont;
}

.centralButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AppFont;
  color: $base-app-color;
}

.ulClass li {
  padding: 0.5rem;
  font-family: AppFont;
}

.socialBox {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.facebookButton {
  width: 100%;
  height: 40px;
}

.faceBookLoginMT {
  width: 35px !important;
  height: 35px !important;
  color: $base-app-color !important;
  float: left;
}

.faceBookLoginText {
  width: 170px;
  height: 30px;
  float: left;
  margin-top: 10px;
  margin-left: 5px;
}

.robotoText {
  font-family: RobotoLight;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  color: #000000;
}

.progressMargin {
  margin: 5px;
}

.hideAuthButtons {
  visibility: hidden;
}

.socialDialog {
  width: 300px;
  height: 100px;
}

.fontStyle {
  font-family: AppFont !important;
}

.blink_me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.greyBox {
  margin-top: 25px;
  background-color: #e5e5e5;
  width: 100%;
  padding: 14px 10px 14px 10px;
  border-radius: 5px;
}
.creatorBox {
  margin-top: 25px;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 14px 10px 14px 10px;
  border-radius: 5px;
  box-shadow: 0px 5px 30px rgba(38, 50, 56, 0.15);
}

.BoxbuttonDiv {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.BoxbuttonStyle {
  /* base styles */
  width: 100%;
  height: 100%;
  /* background:linear-gradient(to right, #8936f4, $base-app-color); */
  background-color: #f6f6f6;
  border-radius: 10px;
  color: #000000;
  border: 0px;
  font-family: RobotoLight;
  font-size: 16px;
}

.CreatorsButtonStyle {
  /* base styles */
  width: 100%;
  height: 100%;
  /* background:linear-gradient(to right, #8936f4, $base-app-color); */
  background-color: #2d69f6;
  border-radius: 10px;
  border: 1px solid white;
  color: white;
  font-family: RobotoLight;
  font-size: 16px;
}

.processBox {
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.processBoxFirst {
  margin-top: 90px;
  font-family: RobotoBlack;
  width: 100%;
  height: 20px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  font-style: normal;
}

.processBoxSecond {
  margin-top: 40px;
  font-family: RobotoLight;
  width: 80%;
  text-align: center;
  line-height: 1.2;
}

.tabBox {
  margin-top: 100px;
  width: 100%;
}

.chooseModel {
  font-family: Roboto;
  font-size: 24px;
  text-align: center;
  height: 24px;
  width: 100%;
  color: $designTextColor;
}

.tabsSwitcher {
  margin-top: 35px;
  width: 100%;
  height: 45px;
  font-family: RobotoLight;
  font-size: 16px;
  color: $designTextColor;
  text-align: center;
}

.leftSwitcher {
  width: 50%;
  float: left;
}
.rightSwitcher {
  width: 50%;
  float: left;
}

.activeTab {
  border-bottom: 2px solid black;
  padding-bottom: 2px;
}

.tabViewBoxCover {
  width: 100%;
  padding: 0px 10px 0px 10px;
}
.tabViewBox {
  margin-top: 30px;
  border: 2px solid #e5e5e5;
  border-radius: 5px;
}

.firstTabViewBox {
  width: 100%;
  height: 168px;
}
.circleSign {
  width: 83px;
  height: 83px;
  background-color: #c4c4c4;
  border-radius: 50px;
  margin-top: 20px;
}

.circleSignText {
  line-height: 83px;
  text-align: center;
  font-size: 16px;
  color: $designTextColor;
  font-family: RobotoLight;
}

.secondBlock {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  height: 45px;
  font-family: RobotoLight;
  color: $designTextColor;
  font-size: 16px;
}

.secondTabViewBox {
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
}

.commonViewBox {
  width: 100%;
  list-style-type: none;
  padding-left: 7px;
}
.commonViewBox li {
  width: 100%;
  padding: 8px;
  overflow: auto;
}

.leftViewBox {
  width: 10%;
  float: left;
  color: #4ba44f;
  line-height: 100%;
}
.rightViewBox {
  width: 90%;
  float: right;
  color: $designTextColor;
  font-family: RobotoLight;
  font-size: 16px;
  line-height: 1.4;
}

.viewBoxButtonStyles {
  margin-top: 15px;
  margin-bottom: 70px;
}

.buttonStylePushTextAdd {
  line-height: 50px;
}

.noMessagesPosition {
  margin-top: 40%;
}

.noMessages {
  width: 80%;
  height: 250px;
}

.payTitle {
  width: 100%;
  height: 100px;

  margin-top: 200px;
}

.payText {
  color: $base-app-color;
  font-size: 24px;
  text-align: center;
  line-height: 100px;
  font-family: AppFont;
}

.fullView {
  width: 140px;
  background-color: #2d69f6;
  border-radius: 30px;
  place-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.fullViewText {
  font-family: RobotoLight;
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  color: #fff;
  line-height: 30px;
  text-align: center;
}

.limiter {
  width: 100%;
  height: 700px;
  overflow: auto;
}

.gridFrameTitle {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.card {
  margin-bottom: 10px !important;
}

.MuiTypography-root {
  color: $base-app-color !important;
}

.projectBox {
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.textOverFlow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.blueColor {
  color: #2d69f6;
}

.projectMarginBottom {
  margin-bottom: 35px;
}
.projectPadding {
  padding: 10px;
}
.projectMarginTop {
  margin-top: 10px;
}
.projectMarginTopDescription {
  margin-top: 20px;
}
.projectMarginTopByPercent {
  margin-top: 10%;
}
.projectPaddingLi li {
  padding: 2px;
}
.projectFont {
  font-family: AppFont;
}

.robotoFont {
  font-family: RobotoLight;
  font-style: normal;
}
.robotoBoldFont {
  font-family: Roboto;
  font-style: normal;
}

.GilroyBlackFont {
  font-family: GilroyBlack;
}
.GilroyRegularFont {
  font-family: GilroyRegular;
}
.GilroyLightFont {
  font-family: GilroyLight;
}
.GilroyBoldFont {
  font-family: GilroyBold;
}

.blackColor {
  color: #000000;
}
.whiteColor {
  color: white !important;
}

.whiteOpacity {
  color: rgba(255, 255, 255, 0.95);
}

.whiteColor th {
  color: #f4f5f7 !important;
}

.whiteColor td {
  color: #f4f5f7 !important;
}
.table > tbody > tr > td {
  color: #f4f5f7 !important;
}

.TitleColor {
  color: #f6f6f6;
}

.DescribtionColor {
  color: #f6f6f6;
}

.centerText {
  text-align: center;
}

.centerElements {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightText {
  text-align: center;
}

.projectTextColor {
  color: $base-app-color !important;
}

.projectContainer {
  margin-bottom: 150px;
}
.opacityControl {
  overflow-y: hidden;
}

.smallTextSize {
  font-size: 16px;
}

.projectFontSize {
  font-size: 18px;
}

.whiteFont {
  color: white;
}

.xsmallFontSize {
  font-size: 14px;
}
.smallFontSize {
  font-size: 16px;
}
.smallXFontSize {
  font-size: 18px;
}

.fullSize {
  width: 100%;
}

.BoxbuttonDivProjectButton {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0px 10px;
}

.BoxbuttonStyleProject {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  border-radius: 10px;
  color: #000000;
  border: 0px;
  font-family: RobotoLight;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #c5bdbd;
}

.deleteUrlClassWithoutText {
  text-decoration: none;
}

.projectBackgroundColor {
  background-color: $base-app-color;
}

.projectStyleButtonFrame {
  border-radius: 10px;
  width: 100%;
  height: 50px;
}
.projectStyleButton {
  line-height: 50px;
}

.buttonStylePush {
  /* base styles */
  margin-left: 5%;
  margin-top: 5px;
  width: 90%;
  height: 50px;
  /* background:linear-gradient(to right, #8936f4, $base-app-color); */
  background: $base-app-color;
  border-radius: 10px;
  border: 1px solid white;
}

.projectPaddingLi li {
  color: $base-app-color;
}
.ShareNameText li {
  color: $base-app-color;
}

.PurpleColor {
  color: #e14eca !important;
}

.BlueColor {
  color: #0083ff !important;
}

.RedColor {
  color: red !important;
}
.orangeColor {
  color: #ff9f29 !important;
}

.textOpacity {
  opacity: 0.7;
}

.projectColor {
  background-color: $base-app-color;
}

#canvas-container{
  width:100vw !important;
  height:100vh !important;
  background-color:#fff;
}
