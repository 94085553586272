$base-app-color: #0083ff;
$font-size: 18px;
$fontStyles: 'Times New Roman', Times, serif;
$greyText: #767676;
$newAppColor: #56c1f7;
$newAppColorTwo: #65c7f7;
$mainTextColor: #00002f;

@font-face {
  font-family: 'AVA_LL';
  src:
    local('AVA_LL'),
    url(../fonts/AVA_LL.TTF) format('TTF');
}
@font-face {
  font-family: 'AVA_LDB';
  src:
    local('AVA_LDB'),
    url(../fonts/AVA_LDB.TTF) format('TTF');
}
@font-face {
  font-family: 'CMP57_';
  src:
    local('CMP57_'),
    url(../fonts/CMP57_.TTF) format('TTF');
}
@font-face {
  font-family: 'CMP58_';
  src:
    local('CMP58_'),
    url(../fonts/CMP58_.TTF) format('TTF');
}
@font-face {
  font-family: 'CMP67_';
  src:
    local('CMP67_'),
    url(../fonts/CMP67_.TTF) format('TTF');
}
@font-face {
  font-family: 'CONC';
  src:
    local('CONC'),
    url(../fonts/CONC.TTF) format('TTF');
}

.businessContainer {
  padding-bottom: 20px;
}

.Login_block {
  width: 100%;
  height: 5vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 200;
}
.Login_image {
  background: url(../images/main/singnup_image.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  height: 100%;
  margin-left: 39%;
  float: left;
}

.Login_image_text {
  text-align: center;
  margin-top: 1px;
  font-size: 14px;
}

.Login_sign_in {
  width: 70px;
  height: 100%;
  float: left;
  margin-left: 19%;
}

.Login_sign_in_text {
  text-align: center;
  font-size: 14px;
  text-decoration: underline;
  color: $mainTextColor;
  margin-top: 2px;
}

@media only screen and (max-width: 2560px) and (max-height: 1440px) {
  .Rolebuttons {
    position: absolute;
    top: 24%;
    left: 70%;
    overflow: hidden;
    width: 10%;
    height: 90px;
    z-index: 25;
  }

  .backgroundCl {
    width: 100%;
    height: 70vh;
    z-index: 1;
    background: url(../images/main/BackgroundVectorTwo.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .dandelionBox {
    width: 100%;
    height: 30%;
    position: absolute;
    left: 0px;
    top: 1%;
    z-index: 20;
  }
}

@media only screen and (max-width: 780px) and (max-height: 1050px) {
  .Rolebuttons {
    position: absolute;
    top: 24%;
    left: 60%;
    overflow: hidden;
    width: 40%;
    height: 90px;
    z-index: 25;
  }

  .dandelionBox {
    width: 100%;
    height: 30%;
    position: absolute;
    left: 0px;
    top: 1%;
    z-index: 20;
  }

  .backgroundCl {
    width: 100%;
    height: 40vh;
    z-index: 1;
    background: url(../images/main/BackgroundVectorTwo.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

@media only screen and (max-width: 420px) and (max-height: 1440px) {
  .Rolebuttons {
    position: absolute;
    top: 26%;
    left: 50%;
    overflow: hidden;
    width: 43%;
    height: 90px;
    z-index: 25;
  }

  .dandelionBox {
    width: 100%;
    height: 30%;
    position: absolute;
    left: 0px;
    top: 1%;
    z-index: 20;
  }

  .backgroundCl {
    width: 100%;
    height: 40vh;
    z-index: 1;
    background: url(../images/main/BackgroundVectorTwo.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
@media only screen and (max-width: 420px) and (max-height: 840px) {
  .Rolebuttons {
    position: absolute;
    top: 26%;
    left: 50%;
    overflow: hidden;
    width: 43%;
    height: 90px;
    z-index: 25;
  }

  .dandelionBox {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 1%;
    z-index: 20;
  }
}

@media only screen and (max-width: 380px) and (max-height: 820px) {
  .Rolebuttons {
    position: absolute;
    top: 26%;
    left: 50%;
    overflow: hidden;
    width: 43%;
    height: 90px;
    z-index: 25;
  }

  .dandelionBox {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 1%;
    z-index: 20;
  }

  .backgroundCl {
    width: 100%;
    height: 40vh;
    z-index: 1;
    background: url(../images/main/BackgroundVectorTwo.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

@media only screen and (max-width: 380px) and (max-height: 650px) {
  .Rolebuttons {
    // background-color: blue;
    position: absolute;
    top: 26%;
    left: 50%;
    overflow: hidden;
    width: 43%;
    height: 90px;
    z-index: 25;
  }

  .dandelionBox {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 1%;
    z-index: 20;
  }

  .backgroundCl {
    width: 100%;
    height: 40vh;
    z-index: 1;
    background: url(../images/main/BackgroundVectorTwo.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.creatorButtonDisabled {
  background-color: #f1f8fc;
  width: 100%;
  height: 45%;
  border-radius: 35px;
  border: 2px solid #337bae;
}
.creatorButtonActive {
  overflow: hidden;
  background: linear-gradient(#2999ff, #5ee6f2);
  width: 100%;
  height: 45%;
  border-radius: 35px;
  border: 0px solid #337bae;
}

.textCreatorActiv {
  margin-top: 8px;
  color: white;
  text-align: center;
}
.textCreatorDisabled {
  margin-top: 8px;
  color: black;
  text-align: center;
}

.creatorButtonTwoDisabled {
  margin-top: 4%;
  background-color: #f1f8fc;
  width: 100%;
  height: 45%;
  border-radius: 35px;
  border: 2px solid #337bae;
}

.creatorButtonTwoActiv {
  overflow: hidden;
  margin-top: 4%;
  background: linear-gradient(#2999ff, #5ee6f2);
  width: 100%;
  height: 45%;
  border-radius: 35px;
  border: 0px solid #337bae;
}

.textCreatorTwoActiv {
  margin-top: 10px;
  color: white;
  text-align: center;
}
.textCreatorTwoDisabled {
  margin-top: 10px;
  color: black;
  text-align: center;
}

.BackgroundText {
  margin-top: 20px;

  width: 100%;
  height: 22vh;
  // text-indent: 46%;
  // line-height: 1.3;
  z-index: 25;
}

.BackgroundText_name {
  width: 100px;
  height: 30px;
  margin-left: 5%;
  z-index: 50;
}
.IO_size {
  font-size: 22px;
  color: #132564;
}
.BackgroundTextV {
  background: url(../images/main/Background_text.png);
  background-repeat: no-repeat;
  background-size: 50%;
  margin-top: 40px;
  color: $mainTextColor;
  width: 100%;
  height: 60px;
  font-size: 18px;
  padding-left: 4px;
  padding-right: 4px;
  // background-color: yellow;
}

.bBackground {
  width: 100%;
  height: 420vh;
  z-index: 1;
  background: url(../images/main/schemeOne.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.businessSchemeBlock {
  width: 100%;
  height: 40px;
  background-color: red;
}
.businessScheme {
  overflow: hidden;
  background: linear-gradient(#2999ff, #5ee6f2);
  width: 70%;
  height: 100%;
  border-radius: 35px;
  margin-left: 15%;
}

.businessSchemeText {
  margin-top: 10px;
  color: white;
  text-align: center;
}

// .vis {
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }

.Business-title {
  width: 100%;
  margin-top: 10px;
}

.Business-title-text {
  color: $base-app-color;
  font-style: $fontStyles;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.Business-desc-one {
  margin-left: 5px;
  margin-right: 5px;
}

.Business-desc-text {
  color: $greyText;
  font-style: $fontStyles;
  font-size: 20px;
  padding: 7px;
  text-align: center;
}

.Business-title-two {
  width: 100%;
}

.Business-title-text-two {
  color: $base-app-color;
  font-style: $fontStyles;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.business-offer {
  width: 100%;
  margin-left: 10px;
  margin-right: 5px;
}

.business-offer-left {
  width: 14%;
  float: left;
  margin-top: 10px;
  text-align: center;
}
.business-offer-left-money {
  width: 14%;
  float: left;
  margin-top: 2.5px;
  text-align: center;
}

.business-offer-right {
  width: 86%;
  float: left;
  color: $greyText;
  font-style: $fontStyles;
  font-size: 18px;
  padding: 7px;
}

.business-icon-color {
  color: $base-app-color !important;
  font-size: 30px !important;
}

.Business-title-three {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.Business-title-text-three {
  margin: 4px;
  color: $base-app-color;
  font-style: $fontStyles;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.Business-titleTwo {
  margin-top: 10px;
  width: 100%;
}

.Business-titleTwo-Text {
  margin: 4px;
  color: $base-app-color;
  font-style: $fontStyles;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.Business-title-four {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.Business-title-four-text {
  margin: 4px;
  color: $base-app-color;
  font-style: $fontStyles;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.creator-icon-color {
  color: $base-app-color !important;
  font-size: 15px !important;
}

.buttonCenter {
  margin-left: 25%;
}

.firstBlockN {
  font-size: 26px;
  margin-top: 60px;
}
.firstBlock {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 17px;
}
.SecondBlock_ {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 17px;
  padding: 0px 22px 0 22px;
}
.LastBlock_ {
  font-size: 36px;
  margin-top: 10px;
  margin-bottom: 17px;
  padding: 0px 22px 0 22px;
}

.LastBlock_subtitles_ {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 17px;
  padding: 0px 22px 0 22px;
}

.blockTwoPaddingDevided {
  margin: 15px 15px 0px 15px;
}

.leftShadowBorder {
  background: rgb(45, 105, 246);
  background: linear-gradient(90deg, rgba(45, 105, 246, 1) 0%, rgba(255, 255, 255, 1) 11%);
  width: 100%;
  padding: 1.5px 1.5px 2px 1.5px;
  border-radius: 2px;
}

.rightShadowBorder {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 89%, rgba(45, 105, 246, 1) 100%);
  width: 100%;
  padding: 1.5px 1.5px 2px 1.5px;
  border-radius: 2px;
}
.whiteBackgroundSquare {
  padding: 17px;
  width: 100%;
  background-color: white;
}

.worldImage {
  max-width: 500px;
}
.growChartImage {
  max-width: 200px;
}
.screenImage {
  max-width: 300px;
}

.firstBlockText {
  line-height: 1.5;
  font-weight: 600;
}
.firstBlockText_N {
  line-height: 1.2;
  font-weight: 600;
}

.blockTwo {
  line-height: 1.2;
  font-weight: 300;
}
.blockTwo_N {
  line-height: 1.7;
  font-weight: 300;
}

.blockTwoPadding {
  padding-left: 30px;
  padding-right: 30px;
}

.getStartedButtonMargin {
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
.getStartedButtonFrame {
  background-color: #e5e5e5;
  border-radius: 10px;
  width: 100%;
  height: 50px;
}
.getStartedButtonFrame_N {
  background-color: #2d69f6;
  border-radius: 25px;
  width: 100%;
  height: 50px;
}
.getStartedButton {
  line-height: 50px;
}

.mainPageDeviderBlock {
  margin-top: 45px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  height: 2px;
}
.mainPageDevider {
  width: 100%;
  height: 2px;
  background-color: #e5e5e5;
}

.millionBlock {
  width: 100%;
  height: 70px;
  // background-color: lightblue;
  border-top: 2px solid #aaadb4;
  margin-top: 46px;
}
.millionBlockOne {
  float: left;
  width: 50%;
  height: 100%;
  //background-color: blue;
}

.millionBlockTwo {
  float: right;
  width: 50%;
  height: 100%;
  // background-color: red;
}

.millionBlockOneCentral {
  width: 100%;
  height: 100%;
}
.millionBlockOneCentralOne {
  float: left;
  width: 25%;
  height: 100%;
}
.millionBlockOneCentralTwo {
  float: right;
  width: 75%;
  height: 100%;
  // background-color: grey;
}
.millionBlockOneCentralOneTop {
  width: 100%;
  height: 50%;
  // background-color: purple;
  overflow: hidden;
}
.millionBlockOneCentralOneBottom {
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.millionBlockOneCentralOneTopCopy {
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.millionBlockOneCentralOneBottomCopy {
  width: 100%;
  height: 50%;
  //background-color: lightblue;
  overflow: hidden;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.greenDot {
  background-color: #78d993;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-left: 72%;
  margin-top: 27px;
  animation: blinker 2s linear infinite;
  // -webkit-box-shadow: -1px 0px 11px 3px #78D993;
  // box-shadow: -1px 0px 11px 3px #78D993;
}
.plusMiddle {
  margin-bottom: 2px;
}
.millionBlockOneCentralOneTopCopyText {
  margin-top: 18px;
}
.millionBlockOneCentralOneBottomCopyText {
  margin-top: 10px;
  margin-left: 2px;
}
.millionBlockOneCentralOneBottomCopyTextTwo {
  margin-top: 10px;
}
